
import { defineComponent } from 'vue';
import { mapGetters } from 'vuex';

import Card from 'primevue/card';
import InputText from 'primevue/inputtext';
import TextArea from 'primevue/textarea';
import InputNumber from 'primevue/inputnumber';
import DataTable from 'primevue/datatable';
import Column from 'primevue/column';

import Utils from '@/utility/utils';

export default defineComponent({
  name: 'LineItemTab',
  components: {
    Card,
    InputText,
    TextArea,
    InputNumber,
    DataTable,
    Column,
  },
  data() {
    return {
      accountId: '',
      currentLiId: '',
      description: '',
      item: {} as any,
    };
  },
  created() {
    this.createdOrUpdated();
  },

  computed: {
    ...mapGetters({
      getLineItem: 'accountingInquiry/getLineItem',
      getActiveTab: 'accountingInquiry/getActiveTab',
    }),
    isReadOnly(): boolean {
      return this.$attrs.readOnly === true;
    },
  },

  methods: {
    initializeItem() {
      return {
        li: '',
        part: '',
        um: '',
        project: '',
        task: '',
        convert_factor: '',
        standard_cost: '',
        std_lot_size: '',
        orig_cost: '',
        cost_group: '',
        rec_qty: '',
        price_per: '',
        actual_cost: '',
        foreign_price: '',
        notes: '',
        amount_items: [
          { amount: ''},
        ],
        dist_items: [
          { acct: '', desc: '', amount: ''},
        ],
        description_items: [],
      };
    },
    getItemDescription() {
      this.description = '';
      if (this.item && this.item.description_items && this.item.description_items.length > 0) {
        this.item.description_items.forEach((desc: any) => {
          this.description += desc.description + '\n';
        });
      }
    },
    computedDiscTotal() {
      const items = this.item.dist_items;
      if (!items || items.length === 0) {
        return '$0.00';
      }
      return this.formatPrice(items.reduce((acc: number, item: any) => acc + parseFloat(item.amount), 0).toString());
    },
    formatPrice(amt: string){
      if (!amt || parseInt(amt) === 0) {
        return '';
      } else {
        return Utils.formatPrice(amt);
      }
    },
    createdOrUpdated() {
      if (this.getActiveTab === 0) {
        this.$router.push("/accounting/accounts-payable");
      }
      this.accountId = this.$route.params.payableId as string;
      this.currentLiId = this.$route.params.itemId as string;

      if (!this.accountId && !this.currentLiId) {
        this.$router.push("/accounting/accounts-payable");
      } else if (this.accountId && !this.currentLiId) {
        this.$router.push(`/accounting/accounts-payable/${this.accountId}`);
      } else if (this.currentLiId && this.accountId) {
        this.item = this.getLineItem(this.accountId, this.currentLiId) ?? this.initializeItem();
        if (this.item) {
          this.item.dist_items = [
            { acct: '15484-5', desc: 'Description 8', amount: '58.0'},
            { acct: '15484-7', desc: 'Description 2', amount: '4254.25'},
            { acct: '15484-0', desc: 'Description 4', amount: '4511'},
            { acct: '15484-1', desc: 'Description 7', amount: '872.99'},
          ]
        } else {
          this.$router.push({ path: '/accounting/' })
        }
        this.getItemDescription();
      }
    },
  },
  watch: {
      '$route.params': {
        immediate: true,
        handler() {
          this.createdOrUpdated();
        }
      }
    }
});
